@font-face {
    font-family: "Thesis";
    src: url("fonts/TheSans_LT_TT5_.eot#");
    src: url("fonts/TheSans_LT_TT5_.woff") format("woff"), url("/fonts/TheSans_LT_TT5_.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Thesis";
    src: url("fonts/TheSans_LT_TT7_.eot#");
    src: url("fonts/TheSans_LT_TT7_.woff") format("woff"), url("fonts/TheSans_LT_TT7_.svg") format("svg");
    font-weight: bold;
    font-style: normal;
}

body {
    font-family: 'Thesis', sans-serif;
    margin: 0;
    padding: 0;
    color: #003480
}

a {
    color: #003480; 
}

.teaserCont {
    margin-bottom: 1rem;
}
.teaserCont:before,
.teaserCont:after {
    display: table;
    content: '';
    float: none;
    clear: both
}

p.inlineImages {
    display: inline-block;
    vertical-align: middle;
}

p.inlineImages img {
    display: inline-block;
    vertical-align: middle;
    margin: 0 .25rem
}

footer nav {
    text-align: right
}

footer nav ul {
    list-style: none;
    margin: 0;
    padding: 0
}
footer nav ul li {
    display: inline-block;
    margin-left: 2rem
}
footer nav ul a {
    color: #fff;
    text-decoration: none;
}
footer nav ul a:hover,
footer nav ul a:focus {
    text-decoration: underline;
}